<template>
  <b-modal
    v-model="_state"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.domiUser.createUser") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <b-row class="no-gutters ml-n3">
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.name"
                label="name"
                veeName="name"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.surname"
                label="surname"
                veeName="surname"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 pl-3">
              <form-input
                v-model="user.email"
                label="email"
                veeName="email"
                :veeRules="{ required: true, email: true }"
              />
            </b-col>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button
            variant="outline-dark"
            class="mr-1"
            @click="setModalState(false)"
          >
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="createDomiUser"
          >
            {{ $t("button.saveChanges") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
export default {
  components: {
    FormInput
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: {}
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    }
  },
  methods: {
    initForm() {
      this.user = {
        name: "",
        surname: "",
        email: "",
        type: "domi"
      };
    },
    async createDomiUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/createUser", {
          userInfo: this.user
        });

        await this.$store.dispatch("domi/retrieveDomiUsers");

        this.setModalState(false);

        this.$store.commit("alert/SET_ALERT", {
          type: "success",
          messageText: "page.domiUser.registrationLinkMessage",
          messageParams: {
            mail: this.user.email
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    setModalState(value) {
      this.$emit("update:state", value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
